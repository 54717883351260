import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom';
import Home from '../pages/Home/Home';
import NotFound from '../pages/NotFound/NotFound';



export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <Home />,
    },

    {
      path: '*',
      element: <NotFound />,
    },
  ]);

  return routes;
}
