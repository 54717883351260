import { changeLanguage } from 'i18next';
import i18n from '../i18n';
import { v4 as uuidv4 } from 'uuid';



export const changeAppLanguage = ()=>{
    let newLanguage = i18n.language==="en"?"ar":"en";

    changeLanguage(newLanguage);

    localStorage.setItem('language', newLanguage);

    // i18n.language==="en"?changeLanguage("ar"):changeLanguage("en");
}

export const getAppLanguage = ()=>{
    return  i18n.language
}

export const getUserId = ()=>{
    let userId = localStorage.getItem('userId');

    if(!userId){

        userId = uuidv4();
        localStorage.setItem('userId',userId )

    }

    return userId;
}