import React from "react";
import styles from "./Loader.module.css";
import { TailSpin } from "react-loader-spinner";

const Loader = () => {
  return (
    <TailSpin
      height="100"
      width="100"
      color="#0073ff"
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
    />
  );
};

export default Loader;
