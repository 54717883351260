import React from 'react'
import styles from './OrderStatusPin.module.css'
import { useTranslation } from 'react-i18next';

const OrderStatusPin = ({status='On the way'}) => {

    const { t, i18n } = useTranslation();
 
 
    return (
    <div className={styles.orderStatusPin}>{t(status)}</div>
  )
}

export default OrderStatusPin