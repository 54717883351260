import React, { useEffect, useState } from "react";
import styles from "./Home.module.css";
import { useTranslation } from "react-i18next";
import Navbar from "../../components/Navbar/Navbar";
import Searchbar from "../../components/Searchbar/Searchbar";
import OrderDetails from "../../components/OrderDetails/OrderDetails";
import OrderTimeline from "../../components/OrderTimeline/OrderTimeline";
import { useSearchParams } from "react-router-dom";
import { getOrder } from "../../services/tracking";
import { set } from "date-fns";
import Loader from "../../components/Loader/Loader";
import Error from "../../components/Error/Error";

function Home() {
  const { t, i18n } = useTranslation();

  const [searchParams] = useSearchParams();

  const [referenceNumber, setReferenceNumber] = useState('');
  const [order, setOrder] = useState();


  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    (async () => {
      let referenceNumber = searchParams.get("referenceNumber");

      if (referenceNumber && referenceNumber !== "") {
        console.log(referenceNumber);
        setReferenceNumber(referenceNumber);

        try {
          setIsLoading(true);
          const res = await getOrder(referenceNumber);

          console.log(res.data.data);

          setOrder(res.data.data);
        } catch (error) {
          console.log(error);
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      }
    })();

    return ()=>{
      setIsError(false);
      setOrder(null);
      setReferenceNumber('');

    }
  }, [searchParams]);

  return (
    <div className={styles.home}>
      <Navbar />

      <Searchbar referenceNumber={referenceNumber} />

      {isLoading && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}

      {isError && <Error />}

      <div className={styles.row}>
        {order ? (
          <>
            <OrderDetails order={order ? order.order : null} firstTimeline={order?order.timeline[0]:null} />

            <OrderTimeline timeline={order ? order.timeline : []} />
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Home;
