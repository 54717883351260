import React from "react";
import styles from "./OrderTimelineStatus.module.css";
import OrderStatusPin from "../OrderStatusPin/OrderStatusPin";

import { ReactComponent as Ellipse } from '../../images/ellipse.svg';
import { ReactComponent as EllipseLight } from '../../images/ellipse-light.svg';
import { ReactComponent as Calendar } from '../../images/calendar.svg';
import { ReactComponent as Clock } from '../../images/clock.svg';
import { format, parse, parseISO } from 'date-fns'
import { useTranslation } from "react-i18next";
import { ar, enUS, arSA } from "date-fns/locale";


const OrderTimelineStatus = ({entry,greyedOut=true}) => {

    const { t, i18n } = useTranslation();


  return (
    <div className={styles.orderTimelineStatus}>
      <div className={styles.status}>
        {greyedOut?<EllipseLight/>:<Ellipse/>}
        <OrderStatusPin status={entry&&entry.Order_Status__c?entry.Order_Status__c:''} />
      </div>

      <div className={styles.timestamp}>
        <div className={styles.date}>
            <Calendar/>
          {entry&&entry.Order_Status_Date__c?format(parseISO(entry.Order_Status_Date__c,), 'iiii, dd MMMM', {locale:i18n.language==="ar"?arSA:enUS}):''}
        </div>
        <div className={styles.time}>
            <Clock/>
            {entry&&entry.Order_Status_Date__c?format(parseISO(entry.Order_Status_Date__c,), 'H:mm a', {locale:i18n.language==="ar"?arSA:enUS}):''}
        </div>
      </div>
    </div>
  );
};

export default OrderTimelineStatus;
