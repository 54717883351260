import React, { useEffect, useState } from "react";
import styles from "./Searchinput.module.css";
import { useTranslation } from "react-i18next";
import { ReactComponent as SearchIcon } from "../../images/searchIcon.svg";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import * as amplitude from '@amplitude/analytics-browser';


const Searchinput = ({referenceNumber=''}) => {
  const { t, i18n } = useTranslation();
  const [query, setQuery] = useState("");

  useEffect(() => {
    if(referenceNumber){
        setQuery(referenceNumber)
    }
  
    // return () => {
    //   second
    // }
  }, [referenceNumber])
  

  const navigate = useNavigate();

  const handleClick = ()=>{
    navigate(`/?referenceNumber=${query}`);

    amplitude.track('Search Button Clicked',{referenceNumber:query});

  }

  const handleKeyPress = e => {
    if (e.key === "Enter") {
        handleClick();
    }
  };

  return (
    <div className={styles.searchinput}>
      <SearchIcon />

      <input
        className={styles.input}
        type="text"
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        placeholder={t("Tracking Number")}
        onKeyDown={handleKeyPress}
        value={query}
      />
      <Button name={t("Search")} marginStart={8} handleOnClick={handleClick} />
    </div>
  );
};

export default Searchinput;
